import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const CloudWrapper = styled.section`
  width: 100%;
  padding: 3rem .5rem;
  background: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.laptop} {
    display: flex;
    padding: 5rem 5rem 5rem 0;
  }
`;

const ImageWrapper = styled.div`
  display: none;

  ${({theme}) => theme.media.laptop} {
    display: block;
    transform: translateY(5rem);
    width: 40%;
  }

  ${({theme}) => theme.media.desktop} {
    width: 50%;
  }
`;

const TextWrapper = styled.div`
  width: 100%;

  ${({theme}) => theme.media.laptop} {
    padding: 5rem 5rem 0 5rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 5rem 20rem 0 20rem;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlue};
  font-size: calc(3.18182vw + 21.81818px);
  line-height: 1.3;
  padding: 1rem;
  margin-bottom: 1rem;

  ${({theme}) => theme.media.tablet} {
    font-size: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 3rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 1rem 3rem 1rem 1rem;
  }
`;

const HeaderP = styled.p`
  color: ${({theme}) => theme.colors.mainWhite};
  font-size: calc(.68182vw + 13.81818px);
  line-height: 1.4;
  font-weight: 500;
  padding: 1rem;

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.tablet} {
    width: 85%;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 1.3rem;
    padding: 1rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 1rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 1rem;
    font-size: 1.4rem;
  }
`;


function CloudOffice() {
  const data = useStaticQuery(graphql`
    query {
      accountingImage: file(relativePath: { eq: "remote-accounting-02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <CloudWrapper>
      <ImageWrapper>
        <Img fluid={data.accountingImage.childImageSharp.fluid} alt="biuro" />
      </ImageWrapper>
      <TextWrapper>
        <H2 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="700">Pracujesz na swoim systemie i programach, z każdego miejsca</H2>
        <HeaderP data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900">Wybierając naszą usługę, nie musisz niczego zmieniać. Nasi specjaliści migrują bazy danych oraz instalują wybrany przez Ciebie system i oprogramowanie. W ramach usługi istnieje możliwość otrzymania sprzętu komputerowego i pełny serwis.</HeaderP>
        <HeaderP data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">E-Pulpit24 dostosowany jest do wszystkich programów InsERT (Subiekt, Rachmistrz, Symfonia), Wapro, Ennova, Comarch Optima, systemów ERP, CRM i innych aplikacji desktopowych, dając gwarancję efektywnej i bezproblemowej pracy.</HeaderP>
      </TextWrapper>
    </CloudWrapper>
  )
}

export default CloudOffice
