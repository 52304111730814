import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const LogoWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem 0;

  ${({theme}) => theme.media.desktop} {
    max-width: 1450px;
    padding: 5rem 0;
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  padding: 1rem;

  ${({theme}) => theme.media.tablet} {
    width: 30%;
  }

  ${({theme}) => theme.media.laptop} {
    width: 20%;
    padding: 2rem;
  }
`;


function LogoOffice() {
  const data = useStaticQuery(graphql`
    query {
      enovaImage: file(relativePath: { eq: "enova365-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      insertImage: file(relativePath: { eq: "Insert-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      optimaImage: file(relativePath: { eq: "optima-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sageImage: file(relativePath: { eq: "sage-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      warpoImage: file(relativePath: { eq: "wapro-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <LogoWrapper>
      <ImageWrapper>
        <Img fluid={data.insertImage.childImageSharp.fluid} alt="Insert" />
      </ImageWrapper>
      <ImageWrapper>
        <Img fluid={data.warpoImage.childImageSharp.fluid} alt="Wapro" />
      </ImageWrapper>
      <ImageWrapper>
        <Img fluid={data.enovaImage.childImageSharp.fluid} alt="Enova" />
      </ImageWrapper>
      <ImageWrapper>
        <Img fluid={data.optimaImage.childImageSharp.fluid} alt="Optima" />
      </ImageWrapper>
      <ImageWrapper>
        <Img fluid={data.sageImage.childImageSharp.fluid} alt="Sage" />
      </ImageWrapper>
    </LogoWrapper>
  )
}

export default LogoOffice
