import React from 'react'


import HeaderOffice from '../components/officeComponents/headerOffice';
import BalanceOffice from '../components/officeComponents/balanceOffice';
import CloudOffice from '../components/officeComponents/cloudOffice';
import LogoOffice from '../components/officeComponents/logoOffice';
import FreeTray from '../components/offerComponents/freeTray';
import AppOffice from '../components/officeComponents/appOffice';
import SEO from "../components/seo";

const AccountingOffice = () => {
  return (
    <>
      <SEO 
        title="Komputer i serwer w chmurze prywatnej już od 59zł/mc."
        description="Serwer w prywatnej chmurze i komputer do logowania to wszystko, czego potrzebujesz do pracy w chmurze. Zestaw do zdalnego stanowiska pracy już od 59zł."
      />

      <HeaderOffice />
      <BalanceOffice />
      <CloudOffice />
      <LogoOffice />
      <FreeTray />
      <AppOffice />
    </>
    
  )
}

export default AccountingOffice