import React from 'react'
import styled from 'styled-components'
import AniLink from "gatsby-plugin-transition-link/AniLink";

import FreeContact from './freeContact';

const FreeTrayWrapper = styled.section`
  width: 100%;
  background: ${({theme}) => theme.colors.mainBlue};

  ${({theme}) => theme.media.tablet} {
    padding: 2rem 0 0 0;
    margin: 3rem 0 0;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 0;
    margin: 5rem 0;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 3rem 0 0 0;
    margin: 7rem 0 4rem;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 45% 1fr;
  }

  ${({theme}) => theme.media.tabletXL} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  ${({theme}) => theme.media.laptop} {
    width: 95%;
    margin: 0 auto;
  }

  ${({theme}) => theme.media.desktop} {
    width: 80%;
  }

  ${({theme}) => theme.media.desktopXL} {
    width: 70%;
  }
`;

const TextBox = styled.div`
  width: 100%;
  padding: 2rem;

  ${({theme}) => theme.media.tabletXL} {
    padding: 2rem 5rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 3rem 8rem 3rem 5rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 3rem 8rem 3rem 4rem;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainWhite};
  font-size: calc(3.18182vw + 21.81818px);
  line-height: 1.2;
  margin-bottom: 1rem;

  span {
    color: ${({theme}) => theme.colors.mainYellow};
  }

  ${({theme}) => theme.media.tablet} {
    font-size: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 3rem;
  }
`;

const TextTitle = styled.p`
  margin: 1rem 0;
  font-weight: 600;
  color: ${({theme}) => theme.colors.mainWhite};

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
  }
`;

const ContactWrapper = styled.div`
  width: 100%;
`;

const MoreInfo = styled.div`
  padding: 2rem;
  width: 100%;
  background: ${({theme}) => theme.colors.mainYellow};

  ${({theme}) => theme.media.laptop} {
    width: 60%;
    padding: 2rem 2rem 2rem 3.5rem;
    clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  }

  ${({theme}) => theme.media.desktop} {
    padding: 2rem 2rem 2rem 6rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 2rem 2rem 2rem 17rem;
  }
`;

const MoreText = styled.p`
position: relative;
font-weight: 400;
color: ${({theme}) => theme.colors.mainBlack};

span {
  position: relative;
  font-weight: 700;

  ::after {
    position: absolute;
    content: '';
    bottom: -4px;
    left: 25%;
    height: 3px;
    width: 50%;
    background: ${({theme}) => theme.colors.mainWhite};
  }

  a {
    color: ${({theme}) => theme.colors.mainBlack};
    text-decoration: none;
  }
}

${({theme}) => theme.media.tabletXL} {
  padding: 0 4rem;
}

${({theme}) => theme.media.laptop} {
  font-size: 1.2rem;

  span {
    font-size: 1.4rem;
  }
}

${({theme}) => theme.media.desktop} {
  font-size: 1.4rem;
  padding-left: 10rem;

  span {
    font-size: 1.6rem;
  }
}
`;

const Button = styled.button`
  font-family: ${({theme}) => theme.font.mainFont};
  display: inline-block;
  text-decoration: none;
  padding: .3rem 1rem;
  margin-top: .8rem;
  color: ${({theme}) => theme.colors.mainWhite};
  border: 2px solid ${({theme}) => theme.colors.mainYellow};
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainWhite};
`;


const FreeTray = () => {
  return (
    <FreeTrayWrapper>
      <InnerWrapper>

        <TextBox>
          <H2 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="700">Komputer w chmurze za <span>0zł</span> przez <span>14 dni</span></H2>
          <TextTitle data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">Nie musisz kupować kota w worku. Zgłoś się do nas i ciesz się okresem testowym przez 14 dni za darmo, bez zobowiązań.</TextTitle>
          <Button 
            data-sal="fade" data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
            as='a'
            target="_blank"
            rel="noopener noreferrer"
            href="https://panel.bluserwer.pl/cart.php?a=add&pid=25"
          >Testuj za darmo</Button>
        </TextBox>

        <ContactWrapper>
          <FreeContact/>
        </ContactWrapper>

      </InnerWrapper>
      <MoreInfo data-sal="fade" data-sal-easing="ease" data-sal-duration="1100">
        <MoreText>Nie wiesz, który plan abonamentowy będzie najlepszy.</MoreText>
        <MoreText>Śmiało, <span><AniLink fade to="/kontakt">napisz do nas</AniLink></span>.</MoreText>
      </MoreInfo>
    </FreeTrayWrapper>
  )
}

export default FreeTray