import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const AppWrapperOuter = styled.div`
  width: 100%;
  position: relative;

  ${({theme}) => theme.media.laptop} {
    &::after {
      position: absolute;
      content: '';
      top: -5rem;
      right: 0;
      width: 40%;
      height: 51%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
      background: rgba(232,222,46,0.4);
      z-index: -1;
    }
  }

  ${({theme}) => theme.media.desktop} {
    &::after {
      width: 41%;
      height: 55%;
    }
  }
  
`;

const AppWrapper = styled.section`
  
  width: 100%;
  padding: 3rem .5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${({theme}) => theme.media.laptop} {
    flex-direction: row;
    max-width: 1300px;
    margin: 0 auto;
    padding: 5rem 3rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 5rem 4rem;
    max-width: 1500px;
  }
`;

const AppBox = styled.div`
  padding: 1rem;
  border: 5px solid ${({theme}) => theme.colors.mainBlue};
  box-shadow: 4px 4px 0px 0px rgba(232,222,46,1), inset 4px 4px 0px 0px rgba(232,222,46,1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  ${({theme}) => theme.media.tablet} {
    flex-direction: row;
    width: 80%;
    margin: 1rem 1rem;
  }

  ${({theme}) => theme.media.laptop} {
    width: 40%;
    margin: 2rem 2rem;
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  padding: 1rem;
  width: 200px;
  max-width: 200px;

  ${({theme}) => theme.media.laptop} {
    width: 250px;
    max-width: 250px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
`;


const TitleWrapper = styled.div`
  width: 100%;
  max-width: 900px;

  .under-title {
    max-width: 650px;
  }
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(3.18182vw + 21.81818px);
  line-height: 1.3;
  padding: 1rem;
  margin-bottom: 1rem;

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.tablet} {
    font-size: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 3rem;
    max-width: 900px;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 1rem 3rem 1rem 1rem;
  }
`;

const HeaderP = styled.p`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(.68182vw + 13.81818px);
  line-height: 1.4;
  font-weight: 500;
  padding: 1rem;

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.tablet} {
    /* width: 85%; */
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 1.1rem;
    padding: 1rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 1rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 1rem;
    font-size: 1.2rem;
  }
`;

const Button = styled.button`
  font-family: ${({theme}) => theme.font.mainFont};
  display: inline-block;
  text-decoration: none;
  padding: .3rem 1rem;
  margin-top: .8rem;
  margin-left: 1rem;
  color: ${({theme}) => theme.colors.mainBlue};
  border: 2px solid ${({theme}) => theme.colors.mainBlue};
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainYellow};
`;


const AppOffice = () => {
  const data = useStaticQuery(graphql`
    query {
      boxEmClientImage: file(relativePath: { eq: "box-emClient.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      boxRachmistrzImage: file(relativePath: { eq: "box-RachmistrzGT.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      boxSageImage: file(relativePath: { eq: "box-Sage.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      boxSubiektImage: file(relativePath: { eq: "box-subiektGT.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <AppWrapperOuter>
      <AppWrapper>
        <TitleWrapper>
          <H2>Za <span>naszym pośrednictwem</span> możesz zakupić jeden z programów</H2>
          <HeaderP className="under-title">Dzięki e-Pulpit24 każdy pracownik mający odpowiednie uprawnienia będzie mógł zalogować się w każdej chwili do swojego programu.</HeaderP>
        </TitleWrapper>
        <AppBox data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="600">
          <ImageWrapper>
            <Img fluid={data.boxSubiektImage.childImageSharp.fluid} alt="SubiektGT" />
          </ImageWrapper>
          <TextWrapper>
            <HeaderP><span>Subiekt GT -</span> nowoczesny, intuicyjny system obsługi sprzedaży i magazynów. Licencja na 3 stanowiska.</HeaderP>
            <Button
              as='a'
              target="_blank"
              rel="noopener noreferrer"
              href="https://panel.bluserwer.pl/cart.php?a=add&pid=23"
            >Kup</Button>
          </TextWrapper>
        </AppBox>

        <AppBox data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="800">
          <ImageWrapper>
            <Img fluid={data.boxRachmistrzImage.childImageSharp.fluid} alt="RachmistrzGT" />
          </ImageWrapper>
          <TextWrapper>
            <HeaderP><span>Rachmistrz GT -</span> księga przychodów i rozchodów i system naliczania podatku zryczałtowanego. Licencja nieograniczon.</HeaderP>
            <Button
              as='a'
              target="_blank"
              rel="noopener noreferrer"
              href="https://panel.bluserwer.pl/cart.php?a=add&pid=24"
            >Kup</Button>
          </TextWrapper>
        </AppBox>

        <AppBox data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">
          <ImageWrapper>
            <Img fluid={data.boxSageImage.childImageSharp.fluid} alt="Sage Symfonia" />
          </ImageWrapper>
          <TextWrapper>
            <HeaderP><span>Sage Symfonia -</span> to program przeznaczony do prowadzenia uproszczonej księgowości. Obsługa pewnsji pracowników.</HeaderP>
            <Button
              as='a'
              target="_blank"
              rel="noopener noreferrer"
              href="https://panel.bluserwer.pl/cart.php?a=add&pid=27"
            >Kup</Button>
          </TextWrapper>
        </AppBox>

        <AppBox data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1200">
          <ImageWrapper>
            <Img fluid={data.boxEmClientImage.childImageSharp.fluid} alt="em-client" />
          </ImageWrapper>
          <TextWrapper>
            <HeaderP><span>eM Client -</span> to całkowicie funkcjonalny klient poczty elektronicznej. Posiada nowoczesny i przyjazny interfejs.</HeaderP>
            <Button
              as='a'
              target="_blank"
              rel="noopener noreferrer"
              href="https://panel.bluserwer.pl/cart.php?a=add&pid=30"
            >Kup</Button>
          </TextWrapper>
        </AppBox>
      </AppWrapper>
    </AppWrapperOuter>
  )
}

export default AppOffice
