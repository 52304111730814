import React from 'react';
import styled from 'styled-components';


const BalanceWrapper = styled.section`
  position: relative;
  width: 100%;
  padding: 3rem .5rem;
  background: ${({theme}) => theme.colors.mainBlue};

  ${({theme}) => theme.media.laptop} {
    display: flex;
    align-items: flex-end;
    padding: 5rem;

    &::after {
      position: absolute;
      content: '';
      top: -6rem;
      right: 0;
      width: 35%;
      height: 6rem;
      background: ${({theme}) => theme.colors.mainBlue};
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 3rem;
      height: 100%;
      background: ${({theme}) => theme.colors.mainBlack};
    }
  }

  ${({theme}) => theme.media.desktop} {
    padding: 10rem 14rem;

    &::after {
      position: absolute;
      content: '';
      top: -9rem;
      right: 0;
      width: 35%;
      height: 9rem;
      background: ${({theme}) => theme.colors.mainBlue};
    }
  }
`;

const LeftTextBox = styled.section`
  width: 100%;
`;

const RightTextBox = styled.section`
  width: 100%;
`;

const H2 = styled.h2`
  color: ${({theme}) => theme.colors.mainWhite};
  font-size: calc(3.18182vw + 21.81818px);
  line-height: 1.3;
  padding: 1rem;
  margin-bottom: 1rem;

  span {
    color: ${({theme}) => theme.colors.mainYellow};
  }

  ${({theme}) => theme.media.tablet} {
    font-size: 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 3rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 1rem 3rem 1rem 1rem;
  }
`;

const HeaderP = styled.p`
  color: ${({theme}) => theme.colors.mainWhite};
  font-size: calc(.68182vw + 13.81818px);
  line-height: 1.4;
  font-weight: 500;
  padding: 1rem;

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.tablet} {
    width: 85%;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 1.3rem;
    padding: 1rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 1rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 1rem;
    font-size: 1.4rem;
  }
`;


const BalanceOffice = () => {
  return (
    <BalanceWrapper>

      <LeftTextBox>
        <H2 data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="700"><span>e-Pulpit24</span> i bilans wszędzie się zgadza</H2>
        <HeaderP data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="900">Cyferki po prawej, cyferki po lewej… Nie jestesmy specjalistami w dziedzinie księgowości ale jedno wiemy na pewno – by firma działała, jak należy, wszystko musi się zgadzać. Bardzo ważna z punktu widzenia nowoczesnego biznesu jest mobilność, dlatego, naszym klientom z biur rachunkowych, dedykujemy usługę księgowość w chmurze, czyli to, na czym my znamy się najlepiej.</HeaderP>
      </LeftTextBox>

      <RightTextBox>
        <HeaderP data-sal="fade" data-sal="slide-up" data-sal-easing="ease" data-sal-duration="1000">Dzięki e-Pulpit24, Ty i pracownicy Twojego biura możecie wykorzystać dowolne urządzenie przenośne z dostępem do internetu do pracy w programach księgowych. Wystawianie faktur oraz inne zdarzenia rachunkowe możecie wprowadzać do systemu przez internet z dowolnego miejsca na świecie. To ogromna zaleta, której nie doświadczysz przy zakupie tradycyjnego serwera do biura rachunkowego.</HeaderP>
      </RightTextBox>

    </BalanceWrapper>
  )
}

export default BalanceOffice
