import React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const HeaderWrapper = styled.section`
  position: relative;
  width: 100%;
  display: flex;

  ${({theme}) => theme.media.tablet} {
    padding-bottom: 4rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding-bottom: 6rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding-bottom: 9rem;
  }
`;

const LeftWrapper = styled.div`
  width: 100%;
  padding: 3rem 0.5rem;

  ${({theme}) => theme.media.laptop} {
    padding: 5rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 5rem 4rem 5rem 14rem;
  }
`

const RightWrapper = styled.div`
  width: 100%;
  display: none;

  ${({theme}) => theme.media.laptop} {
    display: block;
    width: 80%;
  }

  ${({theme}) => theme.media.desktop} {
    width: 80%;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const H1 = styled.h1`
  position: relative;
  color: ${({theme}) => theme.colors.mainBlack};
  padding: 1rem;
  font-size: calc(4.31818vw + 28.18182px);
  line-height: 1.3;

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.tablet} {
    font-size: calc(4.31818vw + 21.18182px);
  }

  ${({theme}) => theme.media.laptop} {
    line-height: 1.1;
    font-size: 4.5rem;
    /* padding: 1rem 5rem 1rem 1rem; */
  }

  ${({theme}) => theme.media.desktop} {
    font-size: 4.5rem;
    padding: 1rem 5rem 1rem 1rem;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 1rem;
    width: 5rem;
    height: 5px;
    background: ${({theme}) => theme.colors.mainYellow};
  }
`;

const HeaderP = styled.p`
  color: ${({theme}) => theme.colors.mainBlack};
  font-size: calc(.68182vw + 13.81818px);
  line-height: 1.4;
  font-weight: 500;
  padding: 1rem;

  span {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  ${({theme}) => theme.media.tablet} {
    width: 80%;
  }

  ${({theme}) => theme.media.laptop} {
    font-size: 1.3rem;
    padding: 2rem 1rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    padding: 1rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 1rem;
    font-size: 1.4rem;
  }
`;


const HeaderOffice = () => {
  const data = useStaticQuery(graphql`
    query {
      remoteImage: file(relativePath: { eq: "remote-accounting-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper>
      <LeftWrapper>
        <H1>A może by tak prowadzić księgowość w <span>chmurze…</span></H1>
        <HeaderP>Na serwerach <span>e-Pulpit24</span>. Poznaj innowacyjną ofertę i zalety naszego rozwiązania! Z nami nie musisz wydawać dużej sumy pieniędzy na zakup serwera, nie będziesz martwić się o jego konfigurację i instalację, a na dodatek zaoszczędzisz na prądzie, który w znacznej ilości pobierany jest przez serwerownie! A bezpieczeństwo? Współpracujemy z wieloma firmami, większymi i mniejszymi, z podmiotami administracji publicznej, urzędami, więc bezpieczeństwo mamy na najwyższym poziomie!</HeaderP>
      </LeftWrapper>
      <RightWrapper>
        <Img fluid={data.remoteImage.childImageSharp.fluid} alt="biuro" />
      </RightWrapper>
    </HeaderWrapper>
  )
}

export default HeaderOffice