import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import styled from 'styled-components'

const FormDiv = styled.div`
  width: 100%;
  padding: 2rem;
  background: ${({theme}) => theme.colors.mainBlack};

  ${({theme}) => theme.media.tablet} {
    padding: 3rem 2rem;
  }

  ${({theme}) => theme.media.tabletXL} {
    padding: 3rem 7rem 3rem 3rem;
  }

  ${({theme}) => theme.media.laptop} {
    width: 80%;
    transform: translateY(-50px);
  }

  ${({theme}) => theme.media.desktop} {
    padding: 5rem 7rem 5rem 3rem;
    transform: translateY(-100px);
  }
`;

const HeaderForm = styled.div`
  width:100%;
`;

const H3 = styled.h3`
  line-height: 1.3;
  margin-bottom: 1rem;
  color: ${({theme}) => theme.colors.mainYellow};

  ${({theme}) => theme.media.desktop} {
    font-size: 1.4rem;
    padding: 0 4rem 0 0;
    margin-bottom: 2rem;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const FormWrapper = styled.div`
  width: 100%;
  font-size: .7rem;
  color: ${({isCheck}) => isCheck ? `#FFF` : `#FF4A37`};
  font-weight: ${({isCheck}) => isCheck ? 300 : 400};
  font-family: ${({theme}) => theme.font.mainFont};
`;

const Input = styled.input`
  font-family: ${({theme}) => theme.font.mainFont};
  width: 100%;
  padding: .5rem;
  margin: .5rem 0;
  border: 3px solid ${({theme}) => theme.colors.mainYellow};

  :focus {
    outline: none;
  }

  ${({theme}) => theme.media.desktop} {
    margin: .8rem 0;
  }
`;

const InputCheckBox = styled.input`
  color: ${({theme}) => theme.colors.mainWhite};
  margin-right: .4rem;
  font-size: .6rem;
`;

const Button = styled.button`
  font-family: ${({theme}) => theme.font.mainFont};
  display: inline-block;
  margin: .7rem 0;
  padding: .3rem 2rem;
  color: ${({theme}) => theme.colors.mainWhite};
  border: 3px solid ${({theme}) => theme.colors.mainYellow};
  letter-spacing: 1px;
  font-weight: 500;
  background: transparent;
  box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainWhite};
  cursor: pointer;
`;

const FinaleMsg = styled.p`
  color: ${({theme}) => theme.colors.mainWhite};
`;


const checkboxAcceptance = 'Wyrażam zgodę na przetwarzanie moich danych osobowych przez BluSoft Sp. z o.o. w celu i w zakresie niezbędnym do realizacji obsługi niniejszego zapytania.';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const API_PATH = 'https://maileepulpit.herokuapp.com/sendMail';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('imię i nazwisko jest wymagane'),
  email: Yup.string().email('adres email jest nieprawidłowy').required('adres e-mail jest wymagany'),
  phone: Yup.string().matches(phoneRegExp, 'numer telefonu jest nieprawidłowy'),
  checkbox: Yup.bool()
  .test(
    'checkbox',
    'wymagana jest zgoda na przetwarzanie danych',
    value => value === true
  )
  .required()
});

const FreeContact = () => {

const [sendMsg, setSend] = useState('')

  return (
      <FormDiv>
        <HeaderForm>
          <H3>Jeżeli masz jakieś pytania, napisz do nas!</H3>
        </HeaderForm>
        <Formik 
          initialValues={{ name: '', email: '', phone: '', checkbox: false, textarea: 'Proszę o kontakt w sprawie komputera w chmurze na 14 dni za darmo' }} 
          validationSchema={validationSchema}
          onSubmit={(values,{setSubmitting, resetForm}) =>{
            setSubmitting(true);

            const qs = require('qs');

            axios.post(`${API_PATH}`, qs.stringify(values, { parseArrays: false } ))
              .then(response => {
              if(response.status === 200) {
                setSend('ok');
                setTimeout(() => {
                  setSend('');
                }, 3000)
                
              }
              setSubmitting(false);
              values.checkbox = false
              resetForm();
            })
              .catch(error => {
              console.log(error)
              setSend('notok');
              setTimeout(() => {
                setSend('');
              }, 3000)
            })

            // setSubmitting(false);
            // resetForm();
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormWrapper>
                <Input
                  type="text" 
                  name="name" 
                  id="name" 
                  autoComplete="off"
                  placeholder="Imię*" 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {touched.name && errors.name ? <p>{errors.name}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper>
                <Input
                  type="email" 
                  name="email" 
                  id="email" 
                  autoComplete="off"
                  placeholder="E-mail*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {touched.email && errors.email ? <p>{errors.email}</p> : <p></p>}
                <Input
                  type="phone" 
                  name="phone" 
                  id="phone" 
                  autoComplete="off"
                  placeholder="Telefon"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                />
                {touched.phone && errors.phone ? <p>{errors.phone}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper isCheck>
                <InputCheckBox
                  type="checkbox"
                  name="checkbox" 
                  id="checkbox" 
                  onChange={handleChange}
                  value={values.checkbox}
                />{checkboxAcceptance}
                {touched.checkbox && errors.checkbox ? <p>{errors.checkbox}</p> : <p></p>}
              </FormWrapper>
              <FormWrapper>
                <Button type="submit" disabled={isSubmitting}>Wyślij</Button>
              </FormWrapper>

              {sendMsg === 'ok' ? <FinaleMsg>Wiadomość została wysłana</FinaleMsg> : <p></p>}
              {sendMsg === 'notok' ? <FinaleMsg>Nie udało się wysłać wiadomości</FinaleMsg> : <p></p>}
            </Form>
          )}
        </Formik>

        </FormDiv>
  )
}

export default FreeContact;